<template>
  <multiselect
    name="multi-destinations-theme2"
    placeholder=""
    :showLabels="false"
    v-model="value"
    :options="availableOptions"
    :multiple="true"
    :closeText="$t('search-tab.choose-count-select')"
    track-by="id"
    :custom-label="customLabel"
    :closeOnSelect="false"
    @select=onSelect($event)
    @remove=onRemove($event)
    @close=selecting
    @open=deselecting
    ref="multiselect"
    >
    <template slot="selection" slot-scope="{ values }">
      <span class="multiselect__single" v-if="values.length === 1 && values[0].id === 0">
        <i class="fa fa-hotel" aria-hidden="true"></i> {{$t("search-tab.all-hotels")}} {{ destination }} {{$t("search-tab.or-select")}}
      </span>
      <span class="multiselect__single" v-else>
        <i class="fa fa-hotel" aria-hidden="true"></i>
        <span v-if="values.length === 1"> {{ values[0].label }} </span>
        <span v-else>{{ values.length }} {{$t("search-tab.hotels-selected")}}</span>
      </span>
    </template>

    <span class="checkbox-label" slot="option" slot-scope="scope" @click.self="select(scope.option)">
      {{ scope.option.label }}
      <input name="checkbox-label" class="multi-checker" type="checkbox" v-model="scope.option.checked" @focus.prevent/>
    </span>
  </multiselect>
</template>

<script>
import TemplateMultipleSelect from './TemplateMultipleSelect';

export default {
  name: 'MultipleSelectTheme2',
  mixins: [TemplateMultipleSelect],
  components: {
    Multiselect: () => import('@/components/atoms/multiSelect/Multiselect'),
  },
};
</script>
<style lang="scss">
.filter-Wrap1{
  .multiselect__strong{
    margin: 0;
  }
  .multiselect__tags, .multiselect__select{
    height: 50px;
    border-radius: 25px;
  }
  .multiselect__tags{
    background-color: #e9ecef;
    border: 1px solid rgba(0, 0, 0, 0.125);
    max-height: 50px;
    overflow: hidden;
    padding: 15px 18px 0 40px;
  }
  .multiselect__option--highlight {
    background: #5897fb;
  }
  .multiselect__tag{
    margin-right: 1px;
    margin-bottom: 1px;
    display: inline-flex;
  }
  .multiselect__content li:first-child{
    margin-bottom: 1.5rem;
    border-bottom: 1px solid;
    >span {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }
  }
  .multiselect__content-wrapper{
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    .close-button{
      position: sticky;
      top: 10px;
      text-align: left;
      z-index: 100;
      height: 0;
    }
  }
  .multiselect--active:not(.multiselect--above) .multiselect__tags{
    border-radius: 25px 25px 0px 0px;
  }
}
</style>
<style lang="scss" scoped>
.multiselect__single {
  padding-right: 9px;
  margin-top: 5px;
  overflow: hidden;
  white-space: nowrap;
  background-color: #e9ecef;
  text-overflow: ellipsis;
  pointer-events: none;
  > i {
   margin-left: 10px;
 }
}
.checkbox-label {
  display: block;
  margin-right: 36px;
}
.multi-checker {
  position: absolute;
  right: 1vw;
}
</style>
