import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      search: '',
      value: [],
      allItem: {
        id: 0,
        value: 0,
        name: 'all',
        label: this.$t('search-tab.all-hotels'),
        checked: true,
      },
      query: this.$route.query,
    };
  },
  components: {
    Multiselect: () => import('@/components/atoms/multiSelect/Multiselect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    list: {
      type: Array,
      default: () => [],
    },
    hotelListShow: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      isRTLPage: 'GET_IS_RLT_PAGE',
    }),
    availableOptions() {
      const optionList = this.list.filter((item) => item.forceValidation && item.hotelName !== null).map((item, inx) => ({
        id: inx + 1,
        value: item.id,
        code: item.hotelExternalCode || item.hotelAtlantisCode,
        atlantisCode: item.hotelAtlantisCode,
        label: item.hotelName,
        checked: false,
        source: item.source ? item.source - 1 : 4,
      }));

      optionList.unshift(this.allItem);
      return optionList;
    },
    stateSelected: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.hotels || [];
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['hotels', value]);
      },
    },
  },
  watch: {
    value() {
      if (this.value.length === 0) {
        this.value.push(this.allItem);
        this.availableOptions[0].checked = true;
      }
      this.$emit('changeHotel', this.value);
      // this.stateSelected = this.value;
    },
    list() {
      this.value = [this.allItem];
      this.availableOptions[0].checked = true;
    },
    stateSelected() {
      this.value = this.stateSelected;
    },
    hotelListShow() {
      this.$refs.multiselect.toggle();
    },
    availableOptions() {
      this.selectInitialValue();
    },
  },
  mounted() {
    this.selectInitialValue();
  },
  methods: {
    selectInitialValue() {
      const qs = (this.query.hotelCode) ? this.query.hotelCode.split(',') : [];
      const qList = this.availableOptions.filter((elm) => qs.findIndex((el) => String(elm.code) === String(el)) > -1);

      if (qList.length > 0) {
        this.value = qList;
        qList.forEach((elm) => this.onSelect(elm));
      } else {
        this.value = [this.allItem];
        this.availableOptions[0].checked = true;
      }
    },
    customLabel(option) {
      return `${option.label}`;
    },
    onSelect(option) {
      if (option.id !== 0) {
        this.availableOptions[0].checked = false;
        if (this.value.findIndex((item) => item.id === 0) > -1) this.value.shift();
      }
      if (option.id === 0) {
        this.availableOptions.forEach((element) => {
          element.checked = false;
        });
        setTimeout(() => { this.value = []; }, 50);
      }
      this.availableOptions[option.id].checked = true;
    },
    onRemove(option) {
      if (option.id !== 0) {
        this.availableOptions[option.id].checked = false;
      }
    },
    select() {
    },
    selecting() {
      this.$emit('optionSelect', true);
    },
    deselecting() {
      this.$emit('optionSelect', false);
    },
  },
};
